<template>
  <b-modal v-model="modalOpen" no-fade size="xl" id="edit-court" ref="edit-court" :no-close-on-backdrop="true" hide-footer hide-header hide-backdrop>
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">Editar Tribunal</h4>
              </div>
              <div class="col-2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <form class="forms-sample">
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="name">Nombre</label>
                  <input v-model="payload.name" type="text" class="form-control" id="name" placeholder="Nombre">
                  <div v-if="errors.hasOwnProperty('name')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.name[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="region">Región</label>
                  <input v-model="payload.region" type="text" class="form-control" id="region" placeholder="Region">
                  <div v-if="errors.hasOwnProperty('region')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.region[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="parent_id">Tribunal Superior</label>
                  <v-select
                    v-model="payload.parent_id"
                    :options="courtsData"
                    id="parent_id"
                    :reduce="r => r.code"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('parent_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.parent_id[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="region">Materias</label>
                  <v-select
                    v-model="payload.subjects"
                    :options="payload.subjects_dropdown"
                    :reduce="r => r"
                    :create-option="subject => ({ code: subject, label: subject })"
                    taggable
                    multiple
                    push-tags
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('subjects')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.subjects[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="address">Dirección</label>
                <textarea v-model="payload.address" name="address" id="address" cols="30" rows="5" class="form-control"></textarea>
                <div v-if="errors.hasOwnProperty('address')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.address[0]}}</small>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="phone">Teléfono</label>
                  <input v-model="payload.phone" type="text" class="form-control" id="phone" placeholder="Teléfono">
                  <div v-if="errors.hasOwnProperty('phone')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.phone[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-auto">
                  <button @click.prevent="updateModel" class="btn btn-gradient-info btn-md">Actualizar</button>
                </div>
              </div>
            </form>
            <div>
              <div class="row align-items-center">
                <div class="col-lg-12 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <div class="page-header">
                        <h3 class="page-title"> Dependientes </h3>
                      </div>
                      <datatable :api="apiEndPoint" :otherParams="{ get_by_court: payload.id }" :fields="fields" :hide-search="true"></datatable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Datatable from '@/components/TableLayout'
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  name: 'ModalUpdate',
  data () {
    return {
      errors: {},
      apiEndPoint: 'api/courts',
      fields: [
        {
          name: 'plain_name',
          title: 'Nombre'
        },
        {
          name: 'region',
          title: 'Región'
        },
        {
          name: 'court',
          title: 'Régimen',
          visible: false,
          formatter: (value) => {
            if (value) {
              return value.name
            }
          }
        },
        {
          name: 'child',
          title: 'Dependientes',
          dataClass: 'text-center',
          formatter: (value) => {
            return value.length
          }
        },
        {
          name: 'phone',
          title: 'Teléfono',
          visible: false
        }
      ],
      selected: {}
    }
  },
  methods: {
    ...mapActions('Court', ['update', 'getCourts']),
    ...mapMutations('Court', ['TOGGLE_MODAL_UPDATE']),
    updateModel () {
      this.update(this.payload)
        .then(() => {
          this.reset()
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    reset () {
      this.name = null
      this.region = null
      this.subjects = []
      this.parent_id = null
      this.address = null
      this.phone = null
      this.errors = {}
      this.$events.fire('refresh-table')
      this.TOGGLE_MODAL_UPDATE(false)
    }
  },
  computed: {
    ...mapGetters('Court', {
      modalUpdate: 'modalUpdate',
      courtsDropdown: 'courts_dropdown'
    }),
    modalOpen () {
      return this.modalUpdate
    },
    courtsData () {
      return this.courtsDropdown
    }
  },
  created () {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'edit-court') {
        this.getCourts({
          exclude: this.payload.id
        })
      }
    })
  },
  components: {
    Datatable
  }
}
</script>
