<template>
  <layout>
    <template slot="content">
      <div>
        <div class="page-header">
          <h3 class="page-title"> Tribunales </h3>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Tribunales</a></li>
              <li class="breadcrumb-item active" aria-current="page">Todos los tribunales</li>
            </ol>
          </nav>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row justify-content-between align-items-center mb-4">
                  <div class="col-auto ml-auto">
                    <button class="btn btn-sm btn-gradient-info" @click.prevent="openModelCreate">
                      <i class="mdi mdi-plus"></i> Nuevo
                    </button>
                  </div>
                </div>
                <datatable :api="apiEndPoint" :fields="fields"></datatable>
              </div>
            </div>
          </div>
        </div>
        <modal-create></modal-create>
        <modal-update v-if="selected" :payload="selected"></modal-update>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/BaseLayout'
import Datatable from '@/components/TableLayout'
import ModalCreate from '@/components/CreateCourt'
import ModalUpdate from '@/components/EditCourt'
import { mapMutations } from 'vuex'

export default {
  name: 'Court',
  data () {
    return {
      apiEndPoint: 'api/courts',
      fields: [
        {
          name: 'name',
          title: 'Nombre'
        },
        {
          name: 'region',
          title: 'Región'
        },
        {
          name: 'court',
          title: 'Régimen',
          formatter: (value) => {
            if (value) {
              return value.name
            }
          }
        },
        {
          name: 'child',
          title: 'Dependientes',
          dataClass: 'text-center',
          formatter: (value) => {
            return value.length
          }
        },
        {
          name: 'phone',
          title: 'Teléfono',
          visible: false
        }
      ],
      selected: {}
    }
  },
  methods: {
    ...mapMutations('Court', ['TOGGLE_MODAL_CREATE', 'TOGGLE_MODAL_UPDATE']),
    openModelCreate () {
      this.TOGGLE_MODAL_CREATE()
    },
    openModelUpdate () {
      this.TOGGLE_MODAL_UPDATE()
    }
  },
  mounted () {
    this.$events.$on('table-item-edit', eventData => {
      this.selected = eventData
      this.openModelUpdate()
    })
  },
  beforeDestroy () {
    this.$events.off('table-item-edit')
  },
  components: {
    Layout,
    Datatable,
    ModalCreate,
    ModalUpdate
  }
}
</script>
