<template>
  <b-modal
    v-model="modalOpen"
    no-fade ref="create-court"
    :no-close-on-backdrop="true"
    hide-footer hide-header hide-backdrop
    size="xl"
  >
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">Agregar Tribunal</h4>
              </div>
              <div class="col-2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <form class="forms-sample">
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="name">Nombre</label>
                  <input v-model="name" type="text" class="form-control" id="name" placeholder="Nombre">
                  <div v-if="errors.hasOwnProperty('name')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.name[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="region">Región</label>
                  <input v-model="region" type="text" class="form-control" id="region" placeholder="Region">
                  <div v-if="errors.hasOwnProperty('region')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.region[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="parent_id">Tribunal Superior</label>
                  <v-select
                    v-model="parent_id"
                    :options="courtsData"
                    id="parent_id"
                    :reduce="r => r.code"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('parent_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.parent_id[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="region">Materias</label>
                  <v-select
                    v-model="subjects"
                    :options="subjectsData"
                    id="subjects"
                    :reduce="r => r.code"
                    :create-option="subject => ({ code: subject, label: subject })"
                    taggable
                    multiple
                    push-tags
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('subjects')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.subjects[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="address">Dirección</label>
                <textarea v-model="address" name="address" id="address" cols="30" rows="5" class="form-control"></textarea>
                <div v-if="errors.hasOwnProperty('address')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.address[0]}}</small>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="phone">Teléfono</label>
                  <input v-model="phone" type="text" class="form-control" id="phone" placeholder="Teléfono">
                  <div v-if="errors.hasOwnProperty('phone')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.phone[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-auto">
                  <button v-if="!processing" @click.prevent="createResource" class="btn btn-gradient-info btn-md">Guardar</button>
                  <button v-else class="btn btn-gradient-light btn-md"><i class="mdi mdi-flattr"></i></button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'CreateCourt',
  data () {
    return {
      processing: false,
      name: null,
      region: null,
      subjects: [],
      parent_id: null,
      address: null,
      phone: null,
      errors: {}
    }
  },
  methods: {
    ...mapActions('Court', ['create', 'getSubjects', 'getCourts']),
    ...mapMutations('Court', ['TOGGLE_MODAL_CREATE']),
    createResource () {
      this.processing = true
      this.create({
        name: this.name,
        region: this.region,
        subjects: this.subjects,
        parent_id: this.parent_id,
        address: this.address,
        phone: this.phone
      })
        .then(() => {
          this.processing = false
          this.reset()
        })
        .catch((error) => {
          this.processing = false
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    reset () {
      this.name = null
      this.region = null
      this.subjects = []
      this.parent_id = null
      this.address = null
      this.phone = null
      this.errors = {}
      this.$events.fire('refresh-table')
      this.TOGGLE_MODAL_CREATE(false)
    }
  },
  mounted () {
    this.getSubjects()
    this.getCourts()
  },
  computed: {
    ...mapGetters('Court', {
      modalCreate: 'modalCreate',
      subjectsStoreData: 'subjects',
      courtsDropdown: 'courts_dropdown'
    }),
    modalOpen: function () {
      this.getSubjects()
      this.getCourts()
      return this.modalCreate
    },
    subjectsData () {
      return this.subjectsStoreData
    },
    courtsData () {
      return this.courtsDropdown
    }
  }
}
</script>
